.slide-out-container {
  @apply fixed inset-0 overflow-hidden z-20;
}
.slide-out-content {
  @apply h-full flex flex-col bg-white shadow-xl overflow-y-scroll pb-40 text-left;
  @apply lg:pb-5;
  .inline-inputs {
    @apply lg:-mx-6 lg:-mt-4;
  }
}
.slide-out-overlay {
  @apply absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity ease-in-out duration-100;
}
.slide-out-slider {
  @apply w-screen max-w-3xl duration-300 ease-in-out transition;
}
