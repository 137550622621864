@import "~stylesheets/styles";

nav#side-nav {
  @apply w-20 top-20 z-20;
  .nav-link {
    @apply block py-3 rounded-md text-sm text-center font-medium;
    &.active {
      @apply text-gray-500;
    }
    &:not(.active) {
      @apply text-gray-300 hover:text-orange;
    }
  }
  #secondary {
    @apply w-20;
  }
}
