.dropdown-control {
  .dropdown-button {
    @apply w-full block focus:outline-none;
    @apply text-xs;
    .icon {
      @apply flex;
    }
  }
  .dropdown-options {
    @apply transition-all ease-out duration-300;
    @apply fixed right-0 left-0 bottom-0;
    @apply lg:bottom-auto lg:absolute;
    @apply mt-0 lg:mt-2 w-full lg:w-56 rounded-lg lg:rounded-md lg:shadow-lg bg-white;
    @apply focus:outline-none ring-1 ring-black ring-opacity-5 overflow-hidden;
    .dropdown-header {
      @apply lg:hidden p-5;
      .close {
        @apply text-right text-gray-300;
      }
      .dropdown-title {
        @apply font-bold text-gray-300 uppercase;
      }
    }
    .dropdown-action {
      @apply block px-5 lg:px-2 py-1 w-full text-left uppercase;
      @apply text-gray-400 lg:text-xs font-bold;
      @apply hover:bg-gray-100 hover:text-orange;
      .material-icons-round {
        @apply  text-3xl lg:text-lg;
      }
    }
    &.top-right {
      @apply lg:origin-top-right lg:left-auto;
    }
    &.top-left {
      @apply lg:origin-top-left lg:right-auto;
    }
  }
  .dropdown-overlay {
    @apply transition-opacity ease-out duration-300 opacity-0;
  }
  &:not(.active) {
    .dropdown-options {
      @apply opacity-0 scale-95 h-0;
    }
  }
  &.active {
    .dropdown-options {
      @apply opacity-100 scale-100 h-5/6 lg:h-auto;
    }
    .dropdown-overlay {
      @apply opacity-100 fixed inset-0 bg-gray-900 bg-opacity-75 lg:hidden;
    }
  }
}
