.section-content {
  .input-container {
    &.inline-input-container {
      @apply mx-0;
    }
  }
  .toggle {
    .input-description {
      @apply mb-0 #{!important};
    }
  }
}
