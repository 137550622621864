.tooltip {
  @apply opacity-0 h-0 w-0 invisible max-w-xs;
  @apply text-gray-900 text-xs text-center;
  @apply bg-white shadow-md rounded;
  @apply transition-opacity duration-500 ease-in-out;
  &.dark {
    @apply bg-gray-700 text-white;
  }
  &[data-show] {
    @apply opacity-100 h-auto w-auto py-1 px-2 visible;
  }

  .arrow {
    @apply absolute invisible;
    width: 8px;
    height: 8px;
    background: inherit;
    &::before {
      @apply absolute visible;
      width: 8px;
      height: 8px;
      background: inherit;
      visibility: visible;
      content: '';
      transform: rotate(45deg);
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;
    }
  }

  &[data-popper-placement^='top'] {
    transition: bottom 500ms;
    bottom: -10px !important;
    &[data-show] {
      bottom: 0px !important;
    }
    &> .arrow {
      bottom: -4px;
    }
  }

  &[data-popper-placement^='bottom'] {
    transition: top 500ms;
    top: -10px !important;
    &[data-show] {
      top: 0px !important;
    }
    &> .arrow {
      top: -4px;
    }
  }

  &[data-popper-placement^='left'] {
    transition: right 500ms;
    right: -10px !important;
    &[data-show] {
      right: 0px !important;
    }
    &> .arrow {
      right: -4px;
    }
  }

  &[data-popper-placement^='right'] {
    transition: left 500ms;
    left: -10px !important;
    &[data-show] {
      left: 0px !important;
    }
    &> .arrow {
      left: -4px;
    }
  }
  &.lg {
    @apply py-3 px-4 text-sm font-medium border border-gray-200 whitespace-nowrap;
    .arrow {
      width: 10px;
      height: 10px;
      &::before {
        width: 10px;
        height: 10px;
      }
    }
    &[data-popper-placement^='top'] {
      &> .arrow {
        bottom: -5px;
        &::before {
          @apply border-b border-r border-gray-200;
        }
      }
    }

    &[data-popper-placement^='bottom'] {
      &> .arrow {
        top: -5px;
        &::before {
          @apply border-t border-l border-gray-200;
        }
      }
    }

    &[data-popper-placement^='left'] {
      &> .arrow {
        right: -5px;
        &::before {
          @apply border-r border-t border-gray-200;
        }
      }
    }

    &[data-popper-placement^='right'] {
      &> .arrow {
        left: -5px;
        &::before {
          @apply border-l border-b border-gray-200;
        }
      }
    }
  }
}
