.pillbox {
  @apply relative;
  .caret {
    @apply absolute text-gray-500;
    top: 26px;
    right: 8px;
    font-size: 21px;
    z-index: 1;
  }
  .selectize-control {
    @apply mb-5;
  }
  .selectize-dropdown {
    height: auto !important;
    border-radius: 0 0 0.25rem 0.25rem !important;
    border: 1px solid #e5e7eb !important;
    border-top: none !important;
    @apply shadow-none;
    .selectize-dropdown-content {
      .option {
        @apply px-3;
        &.active {
          @apply bg-gray-100;
        }
        &.selected {
          @apply font-medium;
        }
      }
    }
  }
  .selectize-input {
    padding-top: 6px;
    padding-bottom: 6px;
    @apply px-3 rounded;
    @apply border-gray-200 shadow-none;
    &.focus {
      @apply shadow-none;
    }
    &.dropdown-active {
      @apply shadow-none;
      input {
        margin-top: 1px;
      }
    }
    &.has-items {
      padding-top: 3px !important;
      padding-bottom: 1px !important;
    }
    &:after {
      display: none !important;
    }
    .item {
      @apply text-sm whitespace-nowrap rounded;
    }
  }
}
