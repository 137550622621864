.searchselect {
  @apply relative;
  .caret {
    @apply absolute text-gray-500;
    top: 6px;
    right: 10px;
    font-size: 21px;
    z-index: 1;
  }
  .selectize-control {
    @apply mb-5;
  }
  .selectize-dropdown {
    height: auto !important;
    border-radius: 0 0 0.25rem 0.25rem !important;
    border: 1px solid #e5e7eb !important;
    border-top: none !important;
    @apply shadow-none;
    .selectize-dropdown-content {
      .option {
        @apply px-3 text-sm;
        &.active {
          @apply bg-gray-100;
        }
        &.selected {
          @apply font-medium;
        }
      }
    }
  }
  .selectize-input {
    @apply px-3 py-1 h-8 rounded flex items-center;
    @apply border-gray-200 shadow-sm;
    &.focus {
      @apply shadow-none;
      input {
        margin-top: 2px !important;
      }
    }
    &.dropdown-active {
      @apply shadow-none;
      input {
        margin-top: 1px;
      }
      .item {
        margin-top: 1px;
      }
    }
    &:after {
      display: none !important;
    }
    .item {
      @apply text-sm;
    }
  }
}

.inline-inputs {
  .searchselect {
    .caret {
      top: 6px;
    }
  }
}
