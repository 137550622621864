@import "~stylesheets/styles";

nav#top-nav {
  @apply hidden;
  @apply absolute lg:fixed top-0 right-0  lg:pr-5 z-10 left-0 h-20 lg:flex;
  @apply lg:bg-white lg:border-b border-gray-200;
  .breadcrumbs {
    .breadcrumb:last-child {
      .chevron {
        @apply hidden;
      }
    }
  }
  .dropdown-control {
    &.lg\:expand {
      @screen md {
        .dropdown-button {
          @apply hidden;
        }
        .dropdown-options {
          @apply opacity-100 scale-100 h-5/6 lg:h-auto shadow-none;
          @apply static ring-0 mt-0;
          .dropdown-content {
            @apply flex;
            .dropdown-action {
              @apply rounded-md;
            }
          }
        }
        .dropdown-overlay {
          @apply opacity-100 fixed inset-0 bg-gray-900 bg-opacity-75 lg:hidden;
        }
      }
    }
  }
  .title {
    @apply font-bold uppercase text-gray-700;
  }
  &.lg\:right-3xl {
    right: 48rem;
  }
}
