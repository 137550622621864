.conditions-control {
  .conditions-list {
    .condition {
      .field-trigger {
        &:hover {
          .remove-condition {
            @apply visible;
          }
        }
      }
      &:last-of-type {
        .connecting-operator-text {
          @apply hidden;
        }
      }
    }
  }
}
