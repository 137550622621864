.wizard-stepper {
  @apply fixed bottom-0 inset-x-52 z-20 h-20 items-center space-x-4;
  @apply hidden lg:flex;
  .stepper-step {
    @apply flex-none text-sm flex justify-center items-center space-x-2;
    @apply text-gray-400 transition-all;
    .stepper-number {
      @apply w-6 h-6 inline-flex items-center justify-center rounded-full;
      @apply bg-gray-400 text-white transition-all;
    }
    &.active {
      @apply text-orange;
      .stepper-number {
        @apply bg-orange;
      }
    }
  }
}
