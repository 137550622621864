.notice {
  @apply text-sm py-2.5 px-4 rounded-md text-white;
  @apply fixed left-5 lg:w-96 right-5 lg:right-auto z-50;
  @apply transition-all duration-1000 ease-in-out;
  .close {
    @apply text-white;
  }
  &.error {
    @apply bg-red-500 text-white;
  }
  &.info {
    @apply bg-gray-500;
  }
  &.success {
    @apply bg-green-500;
  }
}
