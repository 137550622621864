table.table {
  tr.row {
    &[url]:not([url='']) {
      @apply hover:bg-gray-50 cursor-pointer;
    }
  }

  &.selectable {
    tr {
      td:first-child {
        @apply w-8;
      }
      th:first-child {
        @apply w-8;
      }
    }

    .table-select {
      @apply h-4 w-4 rounded border-gray-300 text-orange focus:ring-0 cursor-pointer;
    }

    .table-select-label {
      @apply p-2 cursor-pointer hover:bg-gray-300 rounded-lg;
    }
  }
}
