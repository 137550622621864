#company-onboarding-stepper {
  .step {
    @apply text-gray-400;
    .icon {
      @apply bg-gray-50 border-2 border-gray-400 p-2;
    }
    &.active {
      @apply text-black font-bold;
      .icon {
        @apply bg-black border-black;
      }
    }
    &.complete {
      .icon {
        @apply bg-gray-400 text-white;
      }
    }
  }
}
