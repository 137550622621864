#badges {
  .badge {
    img {
      @apply h-full w-auto;
    }
    .progress-bar {
      .track {
        @apply bg-gray-300;
      }
    }
  }
}
