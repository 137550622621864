.modal-control {
  .modal-container {
    @apply fixed z-10 inset-0 overflow-y-auto;
    .modal-flex {
      @apply flex items-end justify-center items-center min-h-screen;
      @apply pt-4 px-4 pb-20 text-center sm:block sm:p-0;
      .modal-dialogue {
        @apply inline-block align-bottom shadow-xl;
        @apply bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden;
        @apply transform transition-all ease-out duration-300;
        @apply sm:mb-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6;
        .modal-actions {
          @apply lg:flex grid lg:flex-row-reverse gap-x-3 gap-y-3 lg:gap-y-0;
          .button, form.button_to {
            @apply w-full inline-flex;
          }
        }
        .modal-icon-control {
          @apply flex-shrink-0 flex items-center justify-center;
          @apply mx-auto rounded-full;
          @apply lg:mx-0;
        }
      }
      .modal-hack {
        @apply hidden sm:inline-block sm:align-middle sm:h-screen;
      }
      .modal-overlay {
        @apply fixed inset-0 bg-gray-900 bg-opacity-75;
        @apply transition-opacity ease-out duration-300;
      }
    }
  }
}
