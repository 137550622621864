.radiobox {
  label {
    @apply flex cursor-pointer;
    .indicator {
      visibility: hidden;
    }
    &:not([class*="rounded-"]) {
      @apply rounded-lg;
    }
    &:not([class*="bg-"]) {
      @apply bg-orange-50;
    }
    &:not([class*="border-"]) {
      @apply border-2 border-orange-50;
    }
    &:not([class*="text-"]) {
      @apply text-gray-500;
    }
    &:not([class*="p-"]) {
      @apply p-4;
    }
  }
  &.active {
    label {
      @apply text-orange border-orange;
      .indicator {
        visibility: visible;
      }
    }
  }
}
