.slide-out-actions {
  @apply fixed h-20 z-10 flex items-center flex-row-reverse bg-white lg:ml-20;
  @apply inset-x-0 bottom-0 border-t border-gray-200 px-5;
  @apply lg:top-0 lg:right-10 lg:px-0 lg:bg-transparent lg:border-0;
  > div {
    > * {
      @apply flex-grow lg:flex-none;
    }
  }
}
